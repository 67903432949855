import React, { useEffect, useState, Fragment } from "react";
import { Box, Paragraph, RadioButton, FormField, Text } from "grommet";
import styled from "styled-components";
import { Controller, useController } from "react-hook-form";
import { useSelector } from "react-redux";

const StyledRadio = styled(Box)`
  padding: 21px 21px 0 21px;
  width: auto;
  align-self: stretch;
  flex: 1;
  flex-direction: row;
`;

function RadioInputWithInfoComponent({
  name,
  label,
  options,
  info,
  direction = "column",
  control,
  setValue,
}) {
  const defaultValue = useSelector((state) => state.form[name]);
  const [checked, setChecked] = useState(defaultValue);

  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue,
  });

  const onValueChange = (e, index) => {
    setChecked(e.target.value, index);
    onChange(e.target.value);
    setValue(name, e.target.value, { shouldTouch: true });
  };

  return (
    <>
      <Box
        style={{ border: `2px solid ${error ? "#CE614A" : "transparent"}` }}
        direction={direction}
        gap={direction === "column" ? "small" : "medium"}
        margin={{ top: "25px" }}>
        {options.map((option, index) => {
          let { label, info } = option;

          return (
            <Box key={label} style={{ border: "1px solid #0030571f" }}>
              <Box key={option} direction="column">
                <StyledRadio key={index}>
                  <RadioButton
                    name={label}
                    value={label}
                    checked={checked === label}
                    label={label}
                    onChange={(event) => onValueChange(event, label)}
                  />
                </StyledRadio>
                <Box margin={{ bottom: "21px", top: "5px" }}>
                  <Paragraph
                    style={{ opacity: 0.6 }}
                    className="smallPrint straight"
                    margin={{ vertical: "none", horizontal: "58px" }}
                    color="brand">
                    {info}
                  </Paragraph>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default RadioInputWithInfoComponent;
